<template>
  <page-layout
    :title="$t('products_management.upload_invoice_page.title')"
    :has-back-button="true"
    :emit-back="true"
    @back="goBack"
  >
    <template #header-actions>
      <base-button
        :key="$route.name"
        :label="$t('products_management.upload_invoice_page.button_label.upload_invoice')"
        type="primary"
        :loading="loading"
        :disabled="saveDisabled"
        @click="handleSaveSelectedChanges"
      />
    </template>
    <el-form
      ref="form"
      :model="form"
      @submit.prevent.native="null"
      :key="key"
      autocomplete="off"
      id="appUserForm"
      label-position="top"
      v-loading="loading"
    >
      <el-alert type="info" :closable="false" show-icon>
        <span v-html="$t('products_management.upload_invoice_page.alert')"></span>
      </el-alert>
      <p class="text-secondary-title invoicing-import--title">
        {{ $t('products_management.upload_invoice_page.invoice_title') }}
      </p>
      <base-select
        class="kbc__select"
        prop="invoiceType"
        :items="invoiceTypesList"
        v-model="invoiceType"
        name="invoiceType"
        :required="true"
        :validations="$v.invoiceType"
      />
      <p class="invoicing-import--subtitle">
        {{ $t('products_management.upload_invoice_page.invoice_subtitle') }}
      </p>
      <el-upload
        action=""
        class="upload-demo"
        drag
        :on-change="handleUpload"
        :file-list="fileList"
        accept=".xlsx, .csv"
      >
        <i class="ri-upload-cloud-2-line"></i>
        <div
          class="el-upload__text"
          v-html="$t('user_management.import_users_page.uploader_label')"
        />
      </el-upload>
    </el-form>

    <upload-error-table
      id="uploadErrorTable"
      :title="$t('user_management.import_users_page.error_title')"
      :validation-errors="validationErrors"
    ></upload-error-table>

    <upload-success-modal
      :title="''"
      :is-visible="isSuccessModalVisible"
      :data="successModaldata"
      :modal-close="modalClose"
    />

    <upload-nmbs-invoice-modal
      :is-visible="isNmbsModalVisible"
      :set-is-visible="setIsNmbsModalVisible"
      :data="nmbsModalData"
    />
  </page-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import PageLayout from '@/layouts/PageLayout'
import BackofficeUpload from '@/mixins/BackofficeUpload'
import { required } from 'vuelidate/lib/validators'
import UploadNmbsInvoiceModal from '@/components/Backoffice/Uploads/UploadNmbsInvoiceModal'

export default {
  name: 'CompanyUploadNmbsInvoice',
  components: {
    PageLayout,
    UploadNmbsInvoiceModal
  },
  mixins: [BackofficeUpload],
  data () {
    return {
      invoiceType: '',
      isNmbsModalVisible: false,
      nmbsModalData: null
    }
  },
  computed: {
    ...mapGetters({
      corporateAccountOspId: 'corporateAccount/selectedCorporateAccountOspId'
    }),
    invoiceTypesList () {
      return [
        {
          value: 'nmbs',
          label: 'NMBS'
        },
        {
          value: 'bparking',
          label: 'B-Parking'
        }
      ]
    }
  },
  validations: {
    invoiceType: {
      required
    }
  },
  watch: {
    dirty (v) {
      this.saveDisabled = !v
    },
    fileList: {
      deep: true,
      handler (v) {
        this.saveDisabled = !v.length
      }
    }
  },
  methods: {
    getForm () {
      return {
        file: this.fileList[0].raw,
        request: new Blob(
          [
            JSON.stringify({
              type: 'NMBS_BPARKING_SEASON_TICKET_INVOICE_UPLOAD',
              targetOspId: this.corporateAccountOspId
            })
          ],
          { type: 'application/json' }
        )
      }
    },
    handleSaveSelectedChanges () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.invoiceType === 'bparking') {
          this.handleSaveChanges()
        }
        if (this.invoiceType === 'nmbs') {
          this.handleSaveNmbsInvoice()
        }
      }
    },
    async handleSaveNmbsInvoice () {
      this.loading = true
      this.validationErrors = []
      const formData = new FormData()
      formData.append('file', this.fileList[0].raw)
      formData.append('corporateAccountOspId', this.corporateAccountOspId)

      try {
        const uploadId = await this.$api.nmbs.uploadInvoice(formData)
        const validationResponse = await this.$api.nmbs.uploadInvoiceValidate(uploadId, {
          ignoreErrors: '*',
          onError: res => this.showNmbsModal({ error: res })
        })

        if (validationResponse?.validationErrors?.length) {
          this.showNmbsModal({ validationErrors: validationResponse.validationErrors })
        } else {
          await this.$api.nmbs.uploadInvoiceProcess(uploadId, {
            successNotification: this.$t('uploads.success_notification'),
            onSuccess: res => this.showNmbsModal({ processData: res }),
            onError: res => this.showNmbsModal({ processError: res }),
            ignoreErrors: '*'
          })
          this.fileList = []
        }
      } catch (e) {
        console.error('import error ', e)
      }

      this.loading = false
      this.key = this.$key()
    },

    setIsNmbsModalVisible (v) {
      this.isNmbsModalVisible = v
      !v && this.clearNmbsModalData()
    },
    showNmbsModal (data) {
      this.setIsNmbsModalVisible(true)
      this.nmbsModalData = data
    },
    clearNmbsModalData () {
      this.nmbsModalData = null
    }
  },
  async mounted () {
    this.$watch('form', {
      deep: true,
      handler (v) {
        this.dirty = true
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.invoicing-import--title {
  margin: 48px 0 15px;
}

.invoicing-import--subtitle {
  font-size: 13px;
  color: $gray-500;
  margin: 8px 0 15px;
}
</style>
