<template>
  <div class="trip-registration">
    <h2>
      {{ $t('user_group_management.trip_registration_page.commuting_trip_registration.title') }}
    </h2>
    <base-section>
      <base-checkbox
        :label="$t('user_group_management.trip_registration_page.allow_define_km.title')"
        v-model="model.allowDistancesConfiguredByUser"
        :explanation="$t('user_group_management.trip_registration_page.allow_define_km.explanation')"
      ></base-checkbox>
    </base-section>
    <trip-cost-registration-section
      v-model="model.commuteEnabledForCar"
      :ospId="model.corporateAccountOspId"
      :type="this.type"
      :mode="'CAR'"
      :carCommuteCompensationType="model.carCommuteCompensationType"
      @changeTypeHandler="changeTypeHandler"
    />
    <trip-cost-registration-section
      v-model="model.commuteEnabledForBike"
      :ospId="model.corporateAccountOspId"
      :type="this.type"
      :mode="'BIKE'"
    />
    <base-section :with-border="false" style="padding-top: 0">
      <base-checkbox
        :label="$t('user_group_management.trip_registration_page.allow_pay_bike_with_mobility_budget.title')"
        v-model="model.shouldPayBikeAllowanceOnLegalMobilityBudget"
        :explanation="
          $t('user_group_management.trip_registration_page.allow_pay_bike_with_mobility_budget.explanation')
        "
      ></base-checkbox>
    </base-section>
    <trip-cost-registration-section
      v-model="model.commuteEnabledForWalking"
      :ospId="model.corporateAccountOspId"
      :type="this.type"
      :mode="'WALKING'"
    />
  </div>
</template>

<script>
import TripCostRegistrationSection from '../sections/TripCostRegistrationSection'

export default {
  name: 'CommutingTripRegistration',
  data () {
    return {
      type: 'COMMUTE'
    }
  },
  components: {
    TripCostRegistrationSection
  },
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    changeTypeHandler (v) {
      this.value.carCommuteCompensationType = v
    }
  }
}
</script>

<style></style>
