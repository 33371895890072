<template>
  <el-dialog
    id="nmbs-invoice-dialog"
    :close-on-click-modal="true"
    :visible.sync="isVisibleModal"
    @close="setIsVisible(false)"
    width="850px"
    top="8vh"
  >
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('products_management.upload_invoice_page.nmbs_modal.alert')" />
    </el-alert>

    <template v-if="data">
      <div class="result">
        <div class="label">
          <span>
            {{ $t('products_management.upload_invoice_page.nmbs_modal.validation_results') }}
          </span>

          <template v-if="data.error || data.validationErrors">
            <i class="ri-close-fill status-icon error"></i>
            <span>:&nbsp;</span>
          </template>
          <template v-else>
            &nbsp;
            <i class="ri-checkbox-fill status-icon success"></i>
          </template>

          <span v-if="data.error">{{ data.error.getErrorLabel() }}</span>
        </div>

        <el-table
          v-if="hasAlreadyProcessedRecordError"
          id="already-processed-records"
          row-class-name="table-row"
          :data="errorDetailsTableData"
          max-height="350"
          :empty-text="$t('general.empty_table')"
        >
          <el-table-column
            prop="record"
            :label="$t('products_management.upload_invoice_page.nmbs_modal.already_processed')"
          ></el-table-column>
        </el-table>

        <el-table
          v-if="data.validationErrors"
          row-class-name="table-row"
          :data="data.validationErrors"
          max-height="350"
        >
          <el-table-column
            prop="sourceLineNumber"
            :label="$t('products_management.upload_invoice_page.nmbs_modal.line_in_file')"
          ></el-table-column>
          <el-table-column
            prop="extId"
            :label="$t('products_management.upload_invoice_page.nmbs_modal.identifier')"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="reason"
            :label="$t('products_management.upload_invoice_page.nmbs_modal.reason')"
            show-overflow-tooltip
            width="255"
          ></el-table-column>
          <el-table-column
            prop="fieldInError"
            :label="$t('products_management.upload_invoice_page.nmbs_modal.field')"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>

      <div v-if="data.processError || data.processData" class="result">
        <div class="label">
          <span>
            {{ $t('products_management.upload_invoice_page.nmbs_modal.process_result') }}
          </span>

          <template v-if="data.processError || processErrors.length">
            <i class="ri-close-fill status-icon error"></i>
            <span>:&nbsp;</span>
          </template>
          <template v-else>
            &nbsp;
            <i class="ri-checkbox-fill status-icon success"></i>
          </template>

          <span v-if="data.processError">{{ data.processError.getErrorLabel() }}</span>
        </div>

        <div class="process-content">
          <template v-if="successfulRecords.length">
            <div class="success-table-title">
              <span class="table-label">
                {{
                  $t('products_management.upload_invoice_page.nmbs_modal.successfully_processed')
                }}
              </span>
              <base-button
                @click.stop="handleCopy('successfulRecords')"
                size="small"
                prepend-icon="ri-file-copy-line"
              ></base-button>
            </div>
            <el-table
              row-class-name="table-row"
              :data="successfulRecords"
              :max-height="tableHeight"
            >
              <el-table-column
                prop="firstName"
                :label="$t('general.label.first_name')"
                show-overflow-tooltip
                sortable
              ></el-table-column>
              <el-table-column
                prop="lastName"
                :label="$t('general.label.last_name')"
                show-overflow-tooltip
                sortable
              ></el-table-column>
              <el-table-column
                prop="employeeNumber"
                :label="$t('general.label.employee_number')"
                show-overflow-tooltip
                sortable
              ></el-table-column>
            </el-table>
          </template>

          <template v-if="processErrors.length">
            <span class="table-label">
              {{ $t('products_management.upload_invoice_page.nmbs_modal.errors') }}
            </span>
            <el-table row-class-name="table-row" :data="processErrors" :max-height="tableHeight">
              <el-table-column
                prop="sourceLineNumber"
                :label="$t('products_management.upload_invoice_page.nmbs_modal.line_in_file')"
              ></el-table-column>
              <el-table-column
                prop="extId"
                :label="$t('products_management.upload_invoice_page.nmbs_modal.identifier')"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="errorMessage"
                :label="$t('general.label.error')"
                width="255"
              ></el-table-column>
            </el-table>
          </template>

          <template v-if="failedRecords.length">
            <div class="success-table-title">
              <span class="table-label">
                {{ $t('products_management.upload_invoice_page.nmbs_modal.skipped_records') }}
              </span>
              <base-button
                @click.stop="handleCopy('failedRecords')"
                size="small"
                prepend-icon="ri-file-copy-line"
              ></base-button>
            </div>
            <el-table row-class-name="table-row" :data="failedRecords" :max-height="tableHeight">
              <el-table-column
                prop="firstName"
                :label="$t('general.label.first_name')"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="lastName"
                :label="$t('general.label.last_name')"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="employeeNumber"
                :label="$t('general.label.employee_number')"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="processStatus"
                :label="$t('products_management.upload_invoice_page.nmbs_modal.skipped_reason')"
                show-overflow-tooltip
                width="360"
                sortable
              ></el-table-column>
            </el-table>
          </template>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'UploadNmbsInvoiceModal',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    },
    setIsVisible: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    isVisibleModal: {
      get () {
        return this.isVisible
      },
      set (v) {
        this.setIsVisible(v)
      }
    },
    processErrors () {
      return get(this.data, ['processData', 'errors'], []) || []
    },
    successfulRecords () {
      return get(this.data, ['processData', 'summary', 'successfulRecords'], []) || []
    },
    failedRecords () {
      const failedRecords =
        get(this.data, ['processData', 'summary', 'failedRecords'], []) || []
      const importantStatuses = [
        'EMPLOYEE_NOT_FOUND_NO_MATCH_ON_BIRTHDATE',
        'EMPLOYEE_NOT_FOUND_NO_MATCH_ON_LASTNAME'
      ]
      const importantFailedRecords = []
      const otherFailedRecords = []
      failedRecords.forEach(record => {
        importantStatuses.includes(record.processStatus)
          ? importantFailedRecords.push(record)
          : otherFailedRecords.push(record)
      })
      return [...importantFailedRecords, ...otherFailedRecords]
    },
    tableHeight () {
      const totalTables =
        !!this.processErrors.length + !!this.failedRecords.length + !!this.successfulRecords.length
      return totalTables > 1 ? 200 : 400
    },
    hasAlreadyProcessedRecordError () {
      return (
        this.data.error?.getMessage &&
        this.data.error.getMessage() === 'FILE_CONTAINS_ALREADY_PROCESSED_RECORD'
      )
    },
    errorDetailsTableData () {
      const errorDetails =
        get(this.data, ['error', 'error', 'response', 'data', 'details'], []) || []
      return errorDetails.map(el => ({ record: el }))
    }
  },
  methods: {
    handleCopy (recordsName) {
      const preparedText = JSON.stringify(this[recordsName])
      this.$copyText(preparedText).then(() => {
        this.$notify({
          message: this.$t('order_handling.season_card_orders.process_order.copy_success_message'),
          type: 'success'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#nmbs-invoice-dialog {
  .el-dialog__header {
    padding: 14px 0 0;
    button {
      top: 20px;
    }
  }
  .el-dialog__body {
    word-break: normal;
  }
  .el-alert {
    margin: 1rem 0;
  }
  .status-icon {
    font-size: 1.6rem;
    &.error {
      color: red;
    }
    &.success {
      color: green;
    }
  }
  .label {
    display: flex;
    width: fit-content;
  }
  .result {
    margin-top: 1.75rem;
  }
  .process-content {
    margin-left: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  .table-label {
    margin-top: 1rem;
  }
  .label,
  .table-label {
    font-weight: 600;
  }
  .el-table__cell {
    vertical-align: top;
  }
  #already-processed-records {
    .el-table__body-wrapper {
      overflow-x: scroll;
    }
    td {
      .cell {
        white-space: nowrap;
        text-overflow: clip;
        overflow: visible;
      }
    }
  }
  .success-table-title {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    .table-label {
      margin: 0 0.5rem 0 0 !important;
    }
    i.prepend-icon {
      font-size: 1rem;
    }
  }
}
</style>
