<template>
  <el-form
    ref="form"
    :model="model"
    :key="key"
    autocomplete="off"
    id="appUserForm"
    label-position="top"
    v-loading="loading"
    @submit.prevent.native="null"
  >
    <base-section>
      <el-alert type="info" :closable="false" show-icon>
        <span
          v-html="$t('user_group_management.trip_registration_page.alert')"
        ></span>
      </el-alert>
    </base-section>
    <commuting-trip-registration
      v-model="model"
      @handleSaveChanges="handleSaveChanges"
      ref="commutingTrip"
      id="commutingTrip"
    />
    <business-trip-registration
      v-model="model"
      @handleSaveChanges="handleSaveChanges"
      ref="businessTrip"
      id="businessTrip"
    />
  </el-form>
</template>

<script>
import TravelCompensationRepository from '@/app/repositories/TravelCompensationRepository'
import TravelCompensation from '@/app/models/TravelCompensation'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { mapGetters } from 'vuex'
import CommutingTripRegistration from '../components/TripRegistration/CommutingTripRegistration'
import BusinessTripRegistration from '../components/TripRegistration/BusinessTripRegistration'

const travelCompensationRepository = new TravelCompensationRepository()

export default {
  name: 'CompanyTripRegistration',
  components: { CommutingTripRegistration, BusinessTripRegistration },
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    return {
      model: TravelCompensation.getDefault(),
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      commuteCarCompensationPerKmKey: '',
      commuteBikeCompensationPerKmKey: '',
      businessTripCompensationKey: '',
      dirty: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    })
  },
  watch: {
    model: {
      deep: true,
      handler (v) {
        this.checkDirty()
      }
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      travelCompensationRepository.getByCorporateAccountOspId(
        this.corporateAccount.ospId,
        {
          onSuccess: this.setData,
          onFinally: () => {
            this.loading = false
          }
        }
      )
    },
    setData (travelCompensation) {
      this.model = travelCompensation
      this.key = this.$key()
      this.saveDisabled = false
      setTimeout(() => { this.dirty = false }, 100)
    },
    async handleSaveChanges () {
      this.saving = true
      this.loading = true
      await this.model.save({
        successNotification: this.$t(
          'user_group_management.trip_registration_page.success_notification'
        ),
        onSuccess: () => {
          this.corporateAccount.fetchTravelCompensation()
        },
        onFinally: () => {
          this.saving = false
          this.loading = false
        }
      })
      this.checkDirty()
      this.key = this.$key()
    },
    checkDirty () {
      this.dirty = this.model.dirty
      // this.saveDisabled = !this.dirty
    }
  },
  async mounted () {
    this.fetchData()
  }
}
</script>

<style lang="scss">
@import '@/styles/variables';
.trip-registration {
  border-top: 1px solid $gray-200;
}
</style>
