<template>
  <base-section>
    <template v-for="mode in serviceResourceCategories">
      <base-section
        :key="mode"
        :withBorder="false"
        :noPadding="true"
      >
        <p v-if="mode" class="mobility-mode"><i class="text-large ri-bus-line"></i><span
          class="text-secondary-title">{{ $t('general.mobility_mode.' + mode) }}</span></p>
        <div
          :key="'row' + mode"
          class="service-resource-row"
        >
          <template v-for="(resource, idx) in serviceResourcesData[mode || 'null']">
            <div
              :key="mode+idx"
              class="service-resource-item"
            >
              <base-service-resource
                :service-resource="resource"
              >
                <template v-slot:footer="{serviceResource}" v-if="$scopedSlots.footer">
                  <slot :serviceResource="serviceResource" name="footer"></slot>
                </template>
              </base-service-resource>
            </div>
          </template>
        </div>
      </base-section>
    </template>
  </base-section>
</template>
<script>

import { groupBy, keys, sortBy } from 'lodash'

export default {
  name: 'ServiceResourcesSection',
  data () {
    return {
      loading: false,
      key: ''
    }
  },
  props: {
    serviceResources: {
      type: Array
    }
  },
  computed: {
    serviceResourcesData () {
      return groupBy(sortBy(this.serviceResources, 'title'), 'mobilityMode') || {}
    },
    serviceResourceCategories () {
      return keys(this.serviceResourcesData).map(v => v === 'null' ? '' : v).sort()
    }
  },
  methods: {},
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.mobility-mode {
  display: flex;
  align-items: center;
  flex-direction: row;

  i {
    margin-right: 12px;
  }
}
</style>
