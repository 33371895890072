<template>
  <el-form
    ref="form"
    :model="form"
    @submit.prevent.native="null"
    :key="key"
    autocomplete="off"
    id="appUserForm"
    label-position="top"
    v-loading="loading"
  >
    <el-row :gutter="52">
      <el-col :span="9">
        <base-section
          id="person-select-section"
          :title="$t('products_management.nmbs_settings_tab.person_select_section.title')"
        >
          <base-select
            :label="
              $t(
                'products_management.nmbs_settings_tab.season_tickets_section.input_label.backoffice_user'
              )
            "
            ref="user"
            prop="user"
            :validations="$v.form.handlingUserOspId"
            :items="selectData"
            v-model="form.handlingUserOspId"
            name="user"
          ></base-select>
        </base-section>
      </el-col>
      <el-col v-if="isOlympusBackofficeUser" :span="15">
        <base-section id="order-cost-config-section">
          <el-alert type="info" :closable="false" show-icon>
            <span
              v-html="$t('products_management.nmbs_settings_tab.order_cost_config.alert')"
            ></span>
          </el-alert>
          <div style="font-size: 18px">
            <span>
              {{ $t('products_management.nmbs_settings_tab.order_cost_config.amount_title') }}
            </span>
            &nbsp;
            <span style="font-weight: 700">{{ $currency(form.handlingCostAmountVatIncl) }}</span>
            &nbsp;
            <span style="font-size: 16px">{{ $t('general.label.vat_included') }}</span>
          </div>
          <div :key="costTargetTypeKey">
            <base-radio
              v-for="{ labelKey, value, explanationKey } in costTargetTypes"
              :key="value"
              :label="value"
              :value="form.handlingCostBookedOn"
              @input="changeCostTargetType"
              :explanation="$t(explanationKey)"
            >
              {{ $t(labelKey) }}
            </base-radio>
          </div>
        </base-section>
      </el-col>
    </el-row>

    <base-section
      id="alert-section"
      :title="$t('products_management.nmbs_settings_tab.alert_section.title')"
    >
      <el-alert type="info" :closable="false" show-icon>
        <span v-html="$t('products_management.nmbs_settings_tab.alert')"></span>
      </el-alert>
    </base-section>

    <base-section
      id="seasonTrainTickets"
      class="nmbs-tickets-config-section"
      :withBorder="false"
      :title="$t('products_management.nmbs_settings_tab.season_tickets_train_section.title')"
    >
      <div
        v-for="(contract, index) in form.b2bContracts"
        :key="'b2bContract' + contract.id"
        class="nmbs-config-inputs"
      >
        <el-form-item
          :label="
            $t(
              'products_management.nmbs_settings_tab.season_tickets_train_section.input_label.reduction_code'
            )
          "
        >
          <base-input
            prop="b2bContractNumber"
            v-model.trim="contract.b2bContractNumber"
            name="b2bContractNumber"
            :validations="$v.form.b2bContracts.$each.$iter[index].b2bContractNumber"
          ></base-input>
        </el-form-item>

        <el-form-item
          :label="
            $t(
              'products_management.nmbs_settings_tab.season_tickets_train_section.input_label.class_select'
            )
          "
        >
          <base-select
            v-model="contract.classOfService"
            prop="classOfService"
            :items="selectClassOptions"
            name="classOfService"
            :validations="$v.form.b2bContracts.$each.$iter[index].classOfService"
            clearable
          ></base-select>
        </el-form-item>

        <el-form-item :label="$t('general.label.budget')">
          <base-select
            v-model="contract.budgetTypes"
            prop="budgetTypes"
            :items="selectBudgetOptions"
            name="budgetTypes"
            multiple
            collapse-tags
            :validations="$v.form.b2bContracts.$each.$iter[index].budgetTypes"
          ></base-select>
        </el-form-item>

        <base-button
          prepend-icon="ri-delete-bin-2-line"
          size="small"
          class="delete-button"
          @click="handleDelete(contract.id)"
        ></base-button>
      </div>

      <base-button
        v-if="addB2bContractNumberAllowed"
        class="add-circle-button"
        prepend-icon="ri-add-line"
        type="primary"
        :round="false"
        circle
        @click="addB2bContractNumber"
      ></base-button>
    </base-section>

    <base-section
      class="nmbs-tickets-config-section"
      :title="$t('products_management.nmbs_settings_tab.season_tickets_parking_section.title')"
    >
      <base-input
        :label="
          $t(
            'products_management.nmbs_settings_tab.season_tickets_parking_section.input_label.contract_number'
          )
        "
        ref="b2bParkingContractNumber"
        prop="b2bParkingContractNumber"
        v-model="form.b2bParkingContractNumber"
        name="b2bParkingContractNumber"
        :validations="$v.form.b2bParkingContractNumber"
        :max-width="344"
      ></base-input>
    </base-section>

    <base-section
      v-if="$auth.user().isMsp"
      id="singleTicket"
      class="nmbs-tickets-config-section"
      :title="$t('products_management.nmbs_settings_tab.single_tickets_train_section.title')"
    >
      <base-input
        :label="
          $t(
            'products_management.nmbs_settings_tab.single_tickets_train_section.input_label.organism'
          )
        "
        ref="organism"
        prop="organism"
        v-model="form.organism"
        name="organism"
        :validations="$v.form.organism"
        :max-width="344"
      ></base-input>
    </base-section>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { get, cloneDeep, omit } from 'lodash'
import { requiredIf } from 'vuelidate/lib/validators'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { sleep } from '@/app/helpers'
import Api from '@/app/services/ApiService/Api'

const isValidOrganismNumber = orgN => {
  if (orgN) {
    return !orgN.match(/[0-9!@#$%^&*(),./?<>|\\{}[/\]]/) && orgN.length === 4
  }
  return true
}

const requiredIfCallback = nestedModel =>
  nestedModel.b2bContractNumber || nestedModel.budgetTypes.length || nestedModel.classOfService

export default {
  name: 'CompanyNmbsSubscription',
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    return {
      form: {
        b2bContracts: [],
        b2bParkingContractNumber: '',
        corporateAccountOspId: '',
        organism: '',
        username: null,
        password: null,
        handlingCostBookedOn: '',
        handlingUserOspId: '',
        handlingUserName: '',
        handlingCostAmountVatIncl: 0
      },
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      userManagers: [],
      dirty: false,
      addB2bContractNumberAllowed: true,
      initialOrderCostConfig: {
        handlingCostBookedOn: '',
        handlingUserOspId: '',
        handlingCostAmountVatIncl: 0,
        handlingUserName: ''
      },
      costTargetTypeKey: '',
      costTargetTypes: [
        {
          value: 'USER',
          labelKey: 'products_management.nmbs_settings_tab.order_cost_config.user.label',
          explanationKey: 'products_management.nmbs_settings_tab.order_cost_config.user.explanation'
        },
        {
          value: 'COMPANY',
          labelKey: 'products_management.nmbs_settings_tab.order_cost_config.company.label',
          explanationKey:
            'products_management.nmbs_settings_tab.order_cost_config.company.explanation'
        }
      ],
      seasonTicketSuccessFlag: false,
      singleTicketSuccessFlag: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccountOspId: 'corporateAccount/selectedCorporateAccountOspId',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    selectData () {
      return this.userManagers.map(({ userName, userOspId }) => ({
        label: userName,
        value: userOspId
      }))
    },
    selectedUser () {
      return this.userManagers.find(u => u.userOspId === this.form.handlingUserOspId)
    },
    isOlympusBackofficeUser () {
      return !!this.selectedUser && this.selectedUser.olympusBackofficeUser
    },
    selectClassOptions () {
      return [
        {
          value: 'FIRST_CLASS',
          label: this.$t('general.label.first_class')
        },
        {
          value: 'SECOND_CLASS',
          label: this.$t('general.label.second_class')
        }
      ]
    },
    selectBudgetOptions () {
      return [
        {
          value: 'BUSINESS_BUDGET',
          label: this.getBudgetTranslation('BUSINESS_BUDGET')
        },
        {
          value: 'MOBILITY_BUDGET',
          label: this.getBudgetTranslation('MOBILITY_BUDGET')
        },
        {
          value: 'COMMUTING_BUDGET',
          label: this.getBudgetTranslation('COMMUTING_BUDGET')
        },
        {
          value: 'CAFETERIA_BUDGET',
          label: this.getBudgetTranslation('CAFETERIA_BUDGET')
        }
      ]
    }
  },
  validations: {
    form: {
      handlingUserOspId: {
        required: requiredIf(function ({ b2bContracts, b2bParkingContractNumber }) {
          return (Array.isArray(b2bContracts) && b2bContracts.length) || !!b2bParkingContractNumber
        })
      },
      organism: {
        isValidOrganismNumber
      },
      b2bContracts: {
        $each: {
          b2bContractNumber: {
            required: requiredIf(requiredIfCallback)
          },
          budgetTypes: {
            required: requiredIf(requiredIfCallback)
          },
          classOfService: {
            required: requiredIf(requiredIfCallback)
          }
        }
      }
    }
  },
  methods: {
    async fetchBackofficeUsers () {
      try {
        await this.$api.user.getBackofficeUsers(this.corporateAccountOspId, {
          onSuccess: res => {
            this.userManagers = res
          }
        })
      } catch (e) {
        console.error(e)
      }
    },
    async fetchConfig () {
      try {
        await this.$api.nmbs.getSeasonTicketConfiguration(this.corporateAccountOspId, {
          onSuccess: res => this.setSeasonTicketConfigData(res)
        })
      } catch (e) {
        console.error(e)
      }
    },
    async fetchTicketSalesConfig () {
      try {
        await this.$api.nmbs.getTicketSalesConfiguration(this.corporateAccountOspId, {
          onSuccess: res => this.setTicketSalesConfigData(res)
        })
      } catch (e) {
        console.error(e)
      }
    },
    async fetchOrderCostConfig () {
      try {
        await this.$api.order.getConfig(this.corporateAccountOspId, {
          onSuccess: res => this.setOrderCostConfigData(res)
        })
      } catch (e) {
        console.error(e)
      }
    },

    async handleSaveSeasonTicket () {
      const form = {
        b2bContracts: this.form.b2bContracts
          .map(el => omit(el, 'id'))
          .filter(c => Object.values(c).every(v => !!v.length)),
        maxProcessTimeHours: this.form.maxProcessTimeHours,
        corporateAccountOspId: this.corporateAccountOspId,
        b2bParkingContractNumber: this.form.b2bParkingContractNumber || null,
        backofficeUserOspId: this.form.handlingUserOspId
      }
      try {
        await this.$api.nmbs.updateSeasonTicketConfiguration(form, {
          onSuccess: res => {
            this.seasonTicketSuccessFlag = true
            this.setSeasonTicketConfigData(res)
          }
        })
      } catch (err) {
        console.log(err)
        this.seasonTicketSuccessFlag = false
      }
    },
    async handleSaveSingleTicket () {
      if (!this.$auth.user().isMsp || !this.form.organism) {
        return false
      }
      const form = {
        username: this.form.username,
        password: this.form.password,
        organism: this.form.organism,
        assigneeOspId: this.corporateAccountOspId
      }
      try {
        await this.$api.nmbs.updateTicketSalesConfiguration(form, {
          onSuccess: res => {
            this.singleTicketSuccessFlag = true
            this.setTicketSalesConfigData(res)
          }
        })
      } catch (err) {
        console.log(err)
        this.singleTicketSuccessFlag = false
      }
    },
    async updateOrderCostConfig () {
      if (!this.form.handlingUserOspId) {
        return false
      }
      const form = {
        corporateAccountOspId: this.corporateAccountOspId,
        handlingUserOspId: this.form.handlingUserOspId,
        handlingCostBookedOn: this.form.handlingCostBookedOn
      }
      try {
        await this.$api.order.updateConfig(form, {
          onSuccess: res => this.setOrderCostConfigData(res)
        })
        return true
      } catch (err) {
        console.log(err)
        return false
      }
    },

    updateDataAfterSaving () {
      this.saving = false
      this.loading = false
      this.dirty = false

      this.seasonTicketSuccessFlag = false
      this.singleTicketSuccessFlag = false

      this.key = this.$key()
    },
    addB2bContractNumber () {
      const data = cloneDeep(this.form.b2bContracts)
      data.push({ b2bContractNumber: '', budgetTypes: [], classOfService: '', id: this.$key() })
      this.form.b2bContracts = data
    },
    setOrderCostConfigData (data) {
      this.initialOrderCostConfig = data
      this.form.handlingCostBookedOn = get(data, 'handlingCostBookedOn', 'USER')
      this.form.handlingUserOspId = get(data, 'handlingUserOspId', '')
      this.form.handlingUserName = get(data, 'handlingUserName', '')
      this.form.handlingCostAmountVatIncl = get(data, 'handlingCostAmountVatIncl', 0)
    },
    setSeasonTicketConfigData (data) {
      this.form.b2bContracts = (get(data, 'b2bContracts', []) || []).map(el => ({
        ...el,
        budgetTypes: Array.isArray(el.budgetTypes) ? el.budgetTypes : [],
        id: this.$key()
      }))
      this.form.b2bParkingContractNumber = get(data, 'b2bParkingContractNumber', null)
      this.form.corporateAccountOspId = get(data, 'corporateAccountOspId', null)
      this.form.maxProcessTimeHours = get(data, 'maxProcessTimeHours', 168)
    },
    setTicketSalesConfigData (data) {
      this.form.organism = get(data, 'organism', null)
      this.form.password = get(data, 'password', null)
      this.form.username = get(data, 'username', null)
    },
    changeCostTargetType (v) {
      this.form.handlingCostBookedOn = v
      this.costTargetTypeKey = this.$key()
    },

    async handleSaveTransportSettings () {
      this.$v.form.$touch()
      if (this.$v.form.b2bContracts.$invalid) {
        this.$scrollTo('#seasonTrainTickets')
        return false
      }
      if (this.$v.form.organism.$invalid) {
        this.$scrollTo('#singleTicket')
        return false
      }
      this.saving = true
      this.loading = true

      /**  ``for backwards compatibility we left backofficeUserOspId in /seasonTicket/configuration/update API,
       *  but it will be deprecated, but for now just keep passing the backofficeUserOspId without modifications.
       *  If you want to modify backofficeUserOspId you should call portal-service/v1/order/config/update``
       */
      const isSuccessOrderUpdate = await this.updateOrderCostConfig()
      isSuccessOrderUpdate && (await this.handleSaveSeasonTicket())
      await this.handleSaveSingleTicket()

      if (this.seasonTicketSuccessFlag || this.singleTicketSuccessFlag) {
        Api.pushMessage(this.$t('nmbs.success_notification'))
      }

      this.updateDataAfterSaving()
    },
    getBudgetTranslation (budgetType) {
      const type = this.corporateAccount.budgetTypes.find(v => v.budgetType === budgetType)
      return this.$trans(type?.budgetTypeName) || budgetType
    },
    handleDelete (id) {
      this.form.b2bContracts = this.form.b2bContracts.filter(el => el.id !== id)
    }
  },
  watch: {
    'form.handlingUserOspId': {
      handler (v) {
        if (this.selectedUser) {
          this.form.handlingUserName = this.selectedUser.userName
        }

        if (!this.loading) {
          if (this.isOlympusBackofficeUser) {
            if (v === this.initialOrderCostConfig.handlingUserOspId) {
              return this.changeCostTargetType(this.initialOrderCostConfig.handlingCostBookedOn)
            }
            return this.changeCostTargetType('USER')
          }
          return this.changeCostTargetType(null)
        }
      }
    }
  },
  async mounted () {
    this.loading = true
    await Promise.all([
      this.fetchBackofficeUsers(),
      this.fetchConfig(),
      this.fetchTicketSalesConfig(),
      this.fetchOrderCostConfig()
    ]).then(() => {
      this.saveDisabled = false
      this.key = this.$key()
    })
    this.loading = false
    await sleep(10)
    this.dirty = false
    this.$watch('form', {
      deep: true,
      handler (v) {
        this.dirty = true
      }
    })
  }
}
</script>

<style lang="scss">
#alert-section {
  padding-bottom: 0;
}

#alert-section,
#person-select-section {
  flex-direction: column;
  .base-section__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    padding-right: 0;
  }
}

.nmbs-tickets-config-section {
  .base-section__title {
    width: 190px;
  }
  .nmbs-config-inputs {
    display: flex;
    gap: 0 16px;

    @media (max-width: 1280px) {
      flex-wrap: wrap;
      margin-bottom: 24px;
    }
    .el-form-item {
      flex: 1 1 0;
      min-width: 240px;
      max-width: 344px;
    }
    .el-button {
      align-self: center;
      margin-top: 12px;
    }
  }
}

#order-cost-config-section {
  padding-bottom: 30px;
  .base-section__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}
</style>
