import Repository from './Repository'
import ServiceResource from '@/app/models/ServiceResource'
import api from '@/api'

class ServiceResourceRepository extends Repository {
  constructor () {
    super(ServiceResource)
  }

  findAllByAssignee (assigneeOspId, options = {}) {
    const optionsData = this.addWrapper(options)
    return api.product.findAllByAssignee(assigneeOspId, optionsData)
  }

  getByUserGroups (form, options = {}) {
    const optionsData = this.addWrapper(options)
    return api.userGroup.serviceResources(form, optionsData)
  }

  findProductByUserGroup (assigneeOspId, options = {}) {
    const optionsData = this.addWrapper(options)
    return api.product.findProductByUserGroup(assigneeOspId, optionsData)
  }

  findProductByUserGroupAdvanced (ospId, options = {}) {
    return api.product.findProductByUserGroupAdvanced(ospId, options)
  }

  findByCorporateAccount (assigneeOspId, options = {}) {
    const optionsData = this.addWrapper(options)
    return api.product.findByCorporateAccount(assigneeOspId, optionsData)
  }

  add (form, options = {}) {
    return api.product.add(form, options)
  }

  revoke (form, options = {}) {
    return api.product.revoke(form, options)
  }
}

export default ServiceResourceRepository
