<template>
  <base-section class="trip-registration-section">
    <base-checkbox
      :label="
        $t(
          `user_group_management.trip_registration_page.${type.toLowerCase()}_trip_registration.${carType.toLowerCase()}.${mode.toLowerCase()}.enable_label`
        )
      "
      v-model="isEnabled"
      :explanation="
        $t(
          `user_group_management.trip_registration_page.${type.toLowerCase()}_trip_registration.${carType.toLowerCase()}.${mode.toLowerCase()}.enable_explanation`
        )
      "
    ></base-checkbox>
    <div :class="{ 'car-commuting-section': isCommuteCar }">
      <base-radio
        v-if="isCommuteCar"
        v-model="carCommuteCompensationTypeSet"
        :key="carCommuteCompensationTypeSet + '1'"
        label="PER_KILOMETER"
        >{{
          $t('user_group_management.trip_registration_page.commute_trip_registration.no_type.car.per_km.enable_radiobutton')
        }}</base-radio
      >
      <div class="table-container" :class="{ 'table-commuting-car': isCommuteCar }">
        <el-table :data.sync="tableData" :empty-text="emptyText" row-class-name="table-row" style="width: 100%">
          <el-table-column class-name="text-bold" prop="tripCompensation" :label="columnLabel" width="300">
            <template slot-scope="{ row }">
              {{ currency(row.compensationPerKm) }}
              {{ $t('user_group_management.trip_registration_page.input_suffix.per_km') }}
            </template>
          </el-table-column>
          <el-table-column
            prop="validForm"
            :label="$t('user_management.section.trip_registration.table_header.valid_form')"
            width="200"
          >
            <template slot-scope="{ row }">
              {{ $dateFormat(row.validFrom) }}
            </template>
          </el-table-column>
          <el-table-column class-name="actions-cell" width="200">
            <template slot-scope="scope">
              <base-button
                @click.stop="handleEdit(scope.row)"
                size="small"
                prepend-icon="ri-pencil-line"
                :label="$t('general.button_label.edit')"
              />
              <base-button
                @click.stop="handleDelete(scope.row)"
                size="small"
                prepend-icon="ri-delete-bin-2-line"
                :label="$t('general.label.remove')"
              >
              </base-button>
            </template>
          </el-table-column>
        </el-table>
        <base-button
          @click.stop="dialogFormVisible = true"
          prepend-icon="ri-add-line"
          style="float: right;"
          :label="$t('general.label.add')"
        />
      </div>
      <base-radio
        v-if="isCommuteCar"
        v-model="carCommuteCompensationTypeSet"
        :key="carCommuteCompensationTypeSet + '2'"
        :explanation="$t('user_group_management.trip_registration_page.commute_trip_registration.no_type.car.per_day.info_label')"
        label="PER_DAY"
        >{{
          $t('user_group_management.trip_registration_page.commute_trip_registration.no_type.car.per_day.enable_radiobutton')
        }}</base-radio
      >
    </div>
    <el-dialog :visible.sync="dialogFormVisible" @close="dialogClose" :key="dialogKey">
      <el-form :model="form" ref="form">
        <base-currency-input
          :label="$t('user_management.section.trip_registration.input_label.compensation')"
          :max-width="200"
          v-model="form.compensationPerKm"
          name="kmTripRegistration"
          :suffix="$t('user_group_management.trip_registration_page.input_suffix.per_km')"
          :precision="4"
          :required="true"
          :validations="$v.form.compensationPerKm"
        ></base-currency-input>
        <base-datepicker
          v-model="form.validFrom"
          :label="$t('user_management.section.trip_registration.input_label.valid_from')"
          :maxWidth="200"
          :required="true"
          :validations="$v.form.validFrom"
        />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <base-button :label="$t('general.cancel')" @click="dialogFormVisible = false" />
        <base-button
          :loading="updating"
          type="primary"
          :label="edit ? $t('general.button_label.save_changes') : $t('general.label.add')"
          @click="handleUpdate"
        />
      </span>
    </el-dialog>
  </base-section>
</template>

<script>
import { confirmDefaultOptions } from '../../app/helpers'
import { required } from 'vuelidate/lib/validators'

const types = {
  commute: 'COMMUTE',
  business: 'BUSINESS'
}

const modes = {
  car: 'CAR',
  bike: 'BIKE',
  walking: 'WALKING'
}

export default {
  name: 'TripCostRegistrationSection',
  data () {
    return {
      tableData: [],
      dialogFormVisible: false,
      dialogKey: '',
      updating: false,
      edit: false,
      form: {
        compensationPerKm: null,
        validFrom: null
      }
    }
  },
  props: {
    value: {
      type: [Object, Boolean]
    },
    ospId: {
      type: String
    },
    mode: {
      type: String,
      default: 'no_mode'
    },
    type: {
      type: String,
      required: true
    },
    carType: {
      type: String,
      default: 'no_type'
    },
    carCommuteCompensationType: {
      type: String
    }
  },
  computed: {
    emptyText () {
      return 'Not available yet'
    },
    isEnabled: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    carCommuteCompensationTypeSet: {
      get () {
        return this.carCommuteCompensationType
      },
      set (v) {
        this.$emit('changeTypeHandler', v)
      }
    },
    columnLabel () {
      if (this.type === types.commute) {
        return this.$t(
          `user_group_management.trip_registration_page.${this.mode.toLowerCase()}_trip_registration.compensation_label`
        )
      } else {
        return this.$t('user_group_management.trip_registration_page.business_trip_registration.compensation_label')
      }
    },
    isCommuteCar () {
      return this.type === types.commute && this.mode === modes.car
    }
  },
  methods: {
    async fetchData () {
      if (!this.ospId) {
        return
      }
      const filter = {
        corporateAccountOspId: this.ospId,
        mobilityMode: this.mode
      }
      const options = {
        onSuccess: res => {
          this.tableData = res
        }
      }
      try {
        if (this.type === types.commute) {
          await this.$api.compensations.getCommuteTripCompensation(filter, options)
        }
        if (this.type === types.business && this.mode === modes.car) {
          await this.$api.compensations.getBusinessTripCompensation({ ...filter, carType: this.carType }, options)
        }
        if (this.type === types.business && this.mode === modes.bike) {
          await this.$api.compensations.getBusinessTripCompensation(filter, options)
        }
      } catch (e) {
        console.error('e', e)
      }
    },
    handleEdit ({ validFrom, compensationPerKm }) {
      this.form.compensationPerKm = compensationPerKm
      this.form.validFrom = validFrom
      this.edit = true
      this.dialogFormVisible = true
    },
    handleDelete (data) {
      const requestData = {
        compensationPerKm: 0,
        validFrom: this.$date.local().toISODate(),
        mobilityMode: this.mode,
        corporateAccountOspId: this.ospId
      }
      if (data.carType) {
        requestData.carType = data.carType
      }
      this.$confirm(
        this.$t('user_group_management.trip_registration_page.confirm_delete.explanation'),
        this.$t('user_group_management.trip_registration_page.confirm_delete.title'),
        confirmDefaultOptions
      ).then(() => {
        this.updateRequest(requestData)
      })
    },
    dialogClose () {
      this.form.compensationPerKm = null
      this.form.validFrom = null
      this.edit = false
      this.dialogKey = this.$key()
    },
    handleUpdate () {
      if (!this.validate()) {
        return
      }
      const form = {
        ...this.form,
        corporateAccountOspId: this.ospId,
        mobilityMode: this.mode
      }
      if (this.carType !== 'no_type' && this.mode === modes.car) {
        form.carType = this.carType
      }
      return this.updateRequest(form)
    },
    updateRequest (form) {
      this.updating = true
      const options = {
        onSuccess: () => {
          this.dialogFormVisible = false
          this.fetchData()
        },
        onFinally: () => {
          this.updating = false
        }
      }
      if (this.type === types.commute) {
        return this.$api.compensations.updateCommuteTripCompensation(form, options)
      }
      if (this.type === types.business) {
        return this.$api.compensations.updateBusinessTripCompensation(form, options)
      }
    },
    validate () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        return true
      }
    },
    currency (amount) {
      const response =
        '€ ' +
        Number(amount).toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 4
        })
      return response.trim()
    }
  },
  mounted () {
    this.fetchData()
  },
  validations () {
    return {
      form: {
        compensationPerKm: { required },
        validFrom: { required }
      }
    }
  }
}
</script>

<style lang="scss">
.table-container {
  width: 700px; //sum of table columns (300+200+200)
}
.table-commuting-car {
  margin-left: 2em;
  margin-bottom: 4em;
}
.car-commuting-section {
  margin-left: 2em;
}
</style>
