<template>
  <base-section :key="advancedSectionKey" class="resources-advanced-section">
    <div v-if="productConfiguration.length" class="grid-container title" :style="gridColumns">
      <span>{{ $t('general.label.budget') }}</span>
      <span v-for="budgetType in availableBudgets" :key="budgetType">
        {{ getBudgetTranslation(budgetType) }}
      </span>
    </div>
    <span v-else>
      {{ $t('user_management.add_app_users_page.set_budget.no_selected_budgets_alert_text') }}
    </span>

    <div v-for="mode in serviceResourceCategories" :key="mode">
      <div v-if="mode" class="mode__title">
        <span>{{ $t('general.mobility_mode.' + mode) }}</span>
      </div>
      <div class="resource-row">
        <div
          v-for="(resource, idx) in configurableResourcesGrouped[mode || 'null']"
          :key="mode + idx"
          class="service-resource-item grid-container"
          :style="gridColumns"
        >
          <base-service-resource :service-resource="resource" minified />
          <div v-for="budgetType in availableBudgets" :key="budgetType" class="resource-budget">
            <div v-if="resource.disallowedBudgetTypes.includes(budgetType)">
              <span>
                {{ $t('products_management.products_advanced.not_available') }}
              </span>
              &nbsp;
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('products_management.products_advanced.not_allowed_tooltip')"
                placement="top"
              >
                <i class="ri-information-line"></i>
              </el-tooltip>
            </div>
            <div v-else class="checkbox-container">
              <base-checkbox
                :value="resource.enabledBudgetTypes.includes(budgetType)"
                @input="
                  value =>
                    budgetAvailabilityHandler({
                      budgetType,
                      isEnabled: value,
                      product: resource
                    })
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-section>
</template>

<script>
import { groupBy, keys, sortBy } from 'lodash'

export default {
  name: 'ServiceResourcesSectionAdvanced',
  props: {
    serviceResources: {
      type: Array,
      default: () => []
    },
    groupProductsAdvanced: {
      type: [Object, null],
      default: () => ({})
    },
    budgetTypes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      advancedSectionKey: ''
    }
  },
  computed: {
    configuratedResourcesOspIds () {
      return this.productConfiguration.map(el => el.ospId) || []
    },
    configurableResources () {
      return this.serviceResources
        .filter(el => this.configuratedResourcesOspIds.includes(el.ospId))
        .map(el => {
          const resourceConfiguration = this.productConfiguration.find(
            product => product.ospId === el.ospId
          )
          el.enabledBudgetTypes = resourceConfiguration.enabledBudgetTypes
          el.disallowedBudgetTypes = resourceConfiguration.disallowedBudgetTypes
          return el
        })
    },
    configurableResourcesGrouped () {
      return groupBy(sortBy(this.configurableResources, 'title'), 'mobilityMode') || {}
    },
    serviceResourceCategories () {
      return keys(this.configurableResourcesGrouped)
        .map(v => (v === 'null' ? '' : v))
        .sort()
    },
    availableBudgets () {
      return this.groupProductsAdvanced?.budgetTypes.toSorted() || []
    },
    productConfiguration () {
      return this.groupProductsAdvanced?.productConfiguration || []
    },
    gridColumns () {
      const firstColumnWidth = '330px'
      const budgetColumnWidth = '150px'
      return {
        gridTemplateColumns:
          firstColumnWidth + ` ${budgetColumnWidth}`.repeat(this.availableBudgets.length)
      }
    }
  },
  methods: {
    getBudgetTranslation (budgetType) {
      const type = this.budgetTypes.find(v => v.budgetType === budgetType)
      return this.$trans(type.budgetTypeName) || ''
    },
    budgetAvailabilityHandler (data) {
      this.$emit('advancedHandler', data)
    }
  },
  watch: {
    groupProductsAdvanced: {
      deep: true,
      handler () {
        this.advancedSectionKey = this.$key()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.resources-advanced-section {
  width: fit-content;
}
.mode__title {
  font-size: 1rem;
  font-weight: bold;
  background-color: $gray-100;
  padding: 8px 32px;
  border-radius: 4px;
}
.grid-container {
  display: grid;
  gap: 8px;
}
.resource-row > * + * {
  border-top: 1px solid $gray-100;
}
.grid-container.title {
  span:first-child {
    margin-left: 16px;
    text-align: start;
  }
  padding: 16px 0;
  font-weight: bold;
  text-align: center;
}
.resource-budget {
  padding: 16px 0;
  display: flex;
  div {
    margin: auto;
    :deep(.el-checkbox) {
      margin: 0;
    }
    i {
      color: var(--brand-color);
      font-size: 1rem;
    }
  }
}
</style>
