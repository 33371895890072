import { get } from 'lodash'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewUserGroup',
  props: ['userGroupOspId'],
  computed: {
    ...mapGetters({
      $_findByOspId: 'userGroup/findByOspId',
      $_getUserGroupName: 'userGroup/userGroupName'
    }),
    userGroup () {
      return this.userGroupOspId && this.$_findByOspId(this.userGroupOspId)
    },
    userGroupName () {
      return this.userGroup.name
    }
  },
  async beforeRouteEnter (to, from, next) {
    const userGroupOspId = get(to.params, 'userGroupOspId', '')
    if (userGroupOspId && !store.getters['userGroup/findByOspId'](userGroupOspId)) {
      await store.dispatch('userGroup/getByOspId', userGroupOspId)
    }
    next()
  }
}
