<template>
  <el-form
    id="companyProducts"
    ref="form"
    :model="form"
    :key="key"
    autocomplete="off"
    label-position="top"
    v-loading="loading"
    @submit.prevent.native="null"
  >
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('products_management.products.alert')"></span>
    </el-alert>
    <div v-if="showSwitch" class="switch">
      <base-switch
        v-model="isAdvanced"
        :label="$t('products_management.products.simple_advanced_switch')"
        large-label
      />
    </div>

    <template v-if="isAdvanced">
      <service-resources-section-advanced
        :service-resources="filteredCorporateProducts"
        :group-products-advanced="groupProductsAdvanced"
        :budget-types="budgetTypes"
        @advancedHandler="advancedHandler"
      />
    </template>

    <template v-else>
      <div class="filter">
        <el-form ref="form" :inline="true" :model="form" @submit.prevent.native="null">
          <base-input
            v-model.trim="form.search"
            ref="search"
            class="search"
            prop="search"
            name="search"
            auto-complete="off"
            :placeholder="$t('general.search')"
          >
            <template #prependInput>
              <i class="ri-search-line"></i>
            </template>
          </base-input>
        </el-form>
      </div>

      <service-resources-section
        :service-resources="filteredCorporateProducts"
        :edit-list="groupProducts"
        @add="addToGroup"
        @revoke="revokeFromGroup"
      >
        <template v-slot:footer="{ serviceResource }">
          <base-button
            v-if="serviceResourceIsAdded(serviceResource)"
            :loading="resourceButtonLoading[serviceResource.ospId]"
            prepend-icon="ri-check-line"
            type="success"
            @click="revokeFromGroup(serviceResource)"
          >
            {{ $t('products_management.products.product_added_button') }}
          </base-button>
          <base-button
            v-else
            :loading="resourceButtonLoading[serviceResource.ospId]"
            prepend-icon="ri-add-line"
            @click="addToGroup(serviceResource)"
          >
            {{ $t('products_management.products.add_product_button') }}
          </base-button>
        </template>
      </service-resources-section>
    </template>
  </el-form>
</template>

<script>
import ViewUserGroup from '../mixins/ViewUserGroup'
import ServiceResourcesSection from '@/components/sections/ServiceResourcesSection'
import ServiceResourcesSectionAdvanced from '@/components/sections/ServiceResourcesSectionAdvanced'
import ServiceResourceRepository from '@/app/repositories/ServiceResourceRepository'
import { mapGetters } from 'vuex'

const serviceResourceRepository = new ServiceResourceRepository()

export default {
  name: 'CompanyProducts',
  components: {
    ServiceResourcesSection,
    ServiceResourcesSectionAdvanced
  },
  mixins: [ViewUserGroup],
  data () {
    return {
      form: {
        search: ''
      },
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      corporateProducts: [],
      groupProducts: [],
      groupProductsAdvanced: null,
      resourceButtonLoading: {},
      isAdvanced: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount',
      defaultCompanyUserGroup: 'userGroup/defaultCompanyUserGroup'
    }),
    userGroup () {
      return this.defaultCompanyUserGroup(this.corporateAccount.extId)
    },
    filteredCorporateProducts () {
      return this.corporateProducts.filter(v => v.hasLetters(this.form.search))
    },
    budgetTypes () {
      return this.corporateAccount.budgetTypes
    },
    showSwitch () {
      return this.corporateAccount?.selectedBudgetTypes?.length > 1
    }
  },
  methods: {
    async fetchData () {
      const promises = []
      this.loading = true
      if (this.isAdvanced) {
        promises.push(this.fetchGroupProductsAdvanced())
      } else {
        promises.push(this.fetchGroupProducts())
        promises.push(this.fetchCorporateProducts())
      }
      try {
        await Promise.all(promises)
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    fetchGroupProducts (options) {
      return serviceResourceRepository.findProductByUserGroup(this.userGroup.ospId, {
        onSuccess: response => {
          this.groupProducts = response
        },
        ...options
      })
    },
    fetchGroupProductsAdvanced (options) {
      return serviceResourceRepository.findProductByUserGroupAdvanced(this.userGroup.ospId, {
        onSuccess: response => {
          this.groupProductsAdvanced = response
        },
        ...options
      })
    },
    fetchCorporateProducts (options) {
      return serviceResourceRepository.findByCorporateAccount(this.corporateAccount.ospId, {
        onSuccess: response => {
          this.corporateProducts = response
        },
        ...options
      })
    },
    addToGroup (product) {
      this.resourceLoading(product.ospId, true)
      product.addToTarget(this.userGroup.ospId, {
        onSuccess: () => {
          this.fetchGroupProducts({
            onFinally: () => {
              this.resourceLoading(product.ospId, false)
            }
          })
        },
        onError: () => {
          this.resourceLoading(product.ospId, false)
        }
        // successNotification: this.$t('products_management.products.success_notification.add')
      })
    },
    revokeFromGroup (product) {
      this.resourceLoading(product.ospId, true)
      product.revokeFromTarget(this.userGroup.ospId, {
        onSuccess: () => {
          this.fetchGroupProducts({
            onFinally: () => {
              this.resourceLoading(product.ospId, false)
            }
          })
        },
        onError: () => {
          this.resourceLoading(product.ospId, false)
        },
        successNotification: this.$t('products_management.products.success_notification.revoke')
      })
    },
    serviceResourceIsAdded (serviceResource) {
      return !!this.groupProducts.find(v => v.ospId === serviceResource.ospId)
    },
    resourceLoading (serviceResourceOspId, isLoading = false) {
      this.$set(this.resourceButtonLoading, serviceResourceOspId, isLoading)
    },
    advancedHandler ({ product, isEnabled, budgetType }) {
      this.loading = true
      const form = {
        assigneeOspId: this.userGroup.ospId,
        assigneeContextOspId: `OlympusMobility.BUDGET_TYPE.${budgetType}`
      }
      const options = {
        onSuccess: () => {
          this.fetchGroupProductsAdvanced({
            onFinally: () => {
              this.loading = false
            }
          })
        },
        onError: () => {
          this.loading = false
        }
      }
      if (isEnabled) {
        product.addToTarget(form, options)
      } else {
        product.revokeFromTarget(form, options)
      }
    }
  },
  watch: {
    async isAdvanced () {
      await this.fetchData()
    }
  },
  async mounted () {
    await this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
.filter,
.switch {
  margin-top: 32px;
}
</style>
