<template>
  <div class="trip-registration">
    <h2>
      {{
        $t(
          'user_group_management.trip_registration_page.business_trip_registration.title'
        )
      }}
    </h2>
    <trip-cost-registration-section
      v-model="model.businessTripEnabledForPrivateCar"
      :ospId="model.corporateAccountOspId"
      :type="this.type"
      :carType="'PRIVATE'"
      :mode="'CAR'"
    />
    <trip-cost-registration-section
      v-model="model.businessTripEnabledForCompanyCar"
      :ospId="model.corporateAccountOspId"
      :type="this.type"
      :carType="'COMPANY'"
      :mode="'CAR'"
    />
    <trip-cost-registration-section
      v-model="model.businessTripEnabledForBike"
      :ospId="model.corporateAccountOspId"
      :type="this.type"
      :carType="'COMPANY'"
      :mode="'BIKE'"
    />
  </div>
</template>

<script>
import TripCostRegistrationSection from '../sections/TripCostRegistrationSection'

export default {
  components: { TripCostRegistrationSection },
  name: 'BusinessTripRegistration',
  data () {
    return {
      type: 'BUSINESS'
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>

<style></style>
