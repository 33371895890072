<template>
  <page-layout :title="$t('products_management.title')" :has-back-button="true" :emit-back="true" @back="back">
    <template #header-actions>
      <base-button
        v-if="isTransportSettingsTab"
        prepend-icon="ri-add-line"
        :label="$t('products_management.upload_invoice_button')"
        :to="{ name: 'CompanyUploadNmbsInvoice' }"
      />
      <base-button
        v-if="showSaveChanges"
        :key="$route.name"
        :label="$t('general.button_label.save_changes')"
        type="primary"
        :loading="$refs.routerView.saving"
        :disabled="$refs.routerView.saveDisabled"
        @click="handleSaveChanges"
      />
    </template>
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span"><i v-if="tab.icon" :class="tab.icon"></i> {{ tab.label }}</span>
      </el-tab-pane>
    </el-tabs>
    <router-view ref="routerView" />
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import TabNavigation from '../mixins/TabNavigation'
import { get } from 'lodash'

export default {
  name: 'ProductsLayout',
  components: {
    PageLayout
  },
  mixins: [TabNavigation],
  props: {
    emitBack: {
      typ: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeName: '',
      showSaveChanges: false
    }
  },
  methods: {
    handleSaveChanges () {
      if (this.isTransportSettingsTab) {
        return this.$refs.routerView.handleSaveTransportSettings()
      }
      return this.$refs.routerView.handleSaveChanges()
    },
    showButtons () {
      this.showSaveChanges = !!this.$route.meta.showSaveChangesButton
    }
  },
  computed: {
    tabs () {
      return [
        {
          label: this.$t('products_management.title'),
          name: 'CompanyProducts',
          icon: 'ri-building-4-line'
        },
        {
          label: this.$t('products_management.tab_labels.homework_registration'),
          name: 'HomeworkRegistration',
          icon: 'ri-money-euro-circle-line'
        },
        {
          label: this.$t('user_management.view_app_users_page.tab_label.trip'),
          name: 'CompanyTripRegistration',
          icon: 'ri-money-euro-circle-line'
        },
        {
          label: this.$t('products_management.tab_labels.public_transport'),
          name: 'CompanyNmbsSubscription',
          icon: 'ri-train-line'
        }
      ]
    },
    subtitle () {
      const currentTab = this.tabs.find(v => v.name === this.$route.name)
      return get(currentTab, 'label', '')
    },
    isTransportSettingsTab () {
      return this.$route.name === 'CompanyNmbsSubscription'
    }
  }
}
</script>

<style lang="scss"></style>
