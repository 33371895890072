<template>
  <el-form
    ref="form"
    :model="model"
    :key="key"
    autocomplete="off"
    id="appUserForm"
    label-position="top"
    v-loading="loading"
    @submit.prevent.native="null"
  >
    <base-section>
      <el-alert type="info" :closable="false" show-icon>
        <span v-html="$t('products_management.homework_registration_tab.alert')"></span>
      </el-alert>
    </base-section>
    <div class="hw-registration-header">
      <h2>
        {{ $t('user_group_management.hw_registration_page.title') }}
      </h2>
    </div>
    <base-checkbox
      :label="$t('user_group_management.hw_registration_page.enable_label')"
      v-model="model.homeWorkEnabled"
      :explanation="$t(`user_group_management.hw_registration_page.enable_explanation`)"
    ></base-checkbox>
    <div class="table-container">
      <el-table :data.sync="tableData" :empty-text="emptyText" row-class-name="table-row" style="width: 100%">
        <el-table-column
          class-name="text-bold"
          prop="hwCompensation"
          :label="$t('user_group_management.hw_registration_page.table_header.daily_allowance')"
          width="300"
        >
          <template slot-scope="{ row }">
            {{ $currency(row.compensationPerDay) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="validForm"
          :label="$t('user_management.section.trip_registration.table_header.valid_form')"
          width="200"
        >
          <template slot-scope="{ row }">
            {{ $dateFormat(row.validFrom) }}
          </template>
        </el-table-column>
        <el-table-column class-name="actions-cell" width="200">
          <template slot-scope="scope">
            <base-button
              @click.stop="handleEdit(scope.row)"
              size="small"
              prepend-icon="ri-pencil-line"
              :label="$t('general.button_label.edit')"
            />
            <base-button
              @click.stop="handleDelete(scope.row)"
              size="small"
              prepend-icon="ri-delete-bin-2-line"
              :label="$t('general.label.remove')"
            >
            </base-button>
          </template>
        </el-table-column>
      </el-table>
      <base-button
        @click.stop="dialogFormVisible = true"
        prepend-icon="ri-add-line"
        style="float: right;"
        :label="$t('general.label.add')"
      />
    </div>
    <el-dialog :visible.sync="dialogFormVisible" @close="dialogClose" :key="dialogKey">
      <el-form :model="form">
        <base-currency-input
          :label="$t('user_management.section.trip_registration.input_label.daily_allowance')"
          :max-width="200"
          v-model="form.compensationPerDay"
          name="hwRegistration"
          :precision="4"
          :required="true"
          :validations="$v.form.compensationPerDay"
        ></base-currency-input>
        <base-datepicker
          v-model="form.validFrom"
          :label="$t('user_management.section.trip_registration.input_label.valid_from')"
          :maxWidth="200"
          :required="true"
          :validations="$v.form.validFrom"
        />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <base-button :label="$t('general.cancel')" @click="dialogFormVisible = false" />
        <base-button
          :loading="updating"
          type="primary"
          :label="edit ? $t('general.button_label.save_changes') : $t('general.label.add')"
          @click="handleUpdate"
        />
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import TravelCompensationRepository from '@/app/repositories/TravelCompensationRepository'
import TravelCompensation from '@/app/models/TravelCompensation'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { mapGetters } from 'vuex'
import { confirmDefaultOptions } from './../app/helpers'
import { required } from 'vuelidate/lib/validators'

const travelCompensationRepository = new TravelCompensationRepository()

export default {
  name: 'HomeworkRegistration',
  components: {},
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    return {
      model: TravelCompensation.getDefault(),
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false,
      tableData: [],
      dialogFormVisible: false,
      dialogKey: '',
      updating: false,
      edit: false,
      form: {
        compensationPerDay: null,
        validFrom: null
      }
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    emptyText () {
      return this.$t('user_group_management.hw_registration_page.empty_text')
    }
  },
  watch: {
    model: {
      deep: true,
      handler (v) {
        this.checkDirty()
      }
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      travelCompensationRepository.getByCorporateAccountOspId(this.corporateAccount.ospId, {
        onSuccess: this.setData,
        onFinally: () => {
          this.loading = false
        }
      })
    },
    setData (hwCompensation) {
      this.model = hwCompensation
      this.key = this.$key()
      this.saveDisabled = false
      setTimeout(() => {
        this.dirty = false
      }, 100)
    },
    async handleSaveChanges () {
      this.saving = true
      this.loading = true
      await this.model.save({
        successNotification: this.$t('user_group_management.hw_registration_page.success_notification'),
        onSuccess: () => {
          this.corporateAccount.fetchTravelCompensation()
        },
        onFinally: () => {
          this.saving = false
          this.loading = false
        }
      })
      this.checkDirty()
      this.key = this.$key()
    },
    validate () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        return true
      }
    },
    checkDirty () {
      this.dirty = this.model.dirty
      // this.saveDisabled = !this.dirty
    },
    async fetchTableData () {
      try {
        await this.$api.compensations.getHomeworkCompensation(
          {
            corporateAccountOspId: this.corporateAccount.ospId
          },
          {
            onSuccess: res => {
              res.length
                ? (this.tableData = res)
                : (this.tableData = [{ compensationPerDay: 0, validFrom: this.$date.local().toISODate() }])
            }
          }
        )
      } catch (e) {
        console.error('e', e)
      }
    },
    dialogClose () {
      this.form.compensationPerDay = null
      this.form.validFrom = null
      this.edit = false
      this.dialogKey = this.$key()
    },
    handleEdit ({ validFrom, compensationPerDay }) {
      this.form.compensationPerDay = compensationPerDay
      this.form.validFrom = validFrom
      this.edit = true
      this.dialogFormVisible = true
    },
    handleDelete (data) {
      const requestData = {
        compensationPerDay: 0,
        corporateAccountOspId: this.corporateAccount.ospId,
        validFrom: this.$date.local().toISODate()
      }
      this.$confirm(
        this.$t('user_group_management.hw_registration_page.confirm_delete.message'),
        this.$t('user_group_management.hw_registration_page.confirm_delete.title'),
        confirmDefaultOptions
      ).then(() => {
        this.updateRequest(requestData)
      })
    },
    handleUpdate () {
      if (!this.validate()) {
        return
      }
      const form = {
        ...this.form,
        corporateAccountOspId: this.corporateAccount.ospId
      }
      return this.updateRequest(form)
    },
    updateRequest (form) {
      this.updating = true
      return this.$api.compensations.updateHomeworkCompensation(form, {
        onSuccess: () => {
          this.dialogFormVisible = false
          this.fetchTableData()
        },
        onFinally: () => {
          this.updating = false
        }
      })
    }
  },
  async mounted () {
    this.fetchData()
    this.fetchTableData()
  },
  validations () {
    return {
      form: {
        compensationPerDay: { required },
        validFrom: { required }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables';
.hw-registration-header {
  border-top: 1px solid $gray-200;
}
.table-container {
  width: 700px; //sum of table columns (300+200+200)
}
</style>
