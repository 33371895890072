import { cloneDeep } from 'lodash'
import ServiceResourceRepository from '@/app/repositories/ServiceResourceRepository'
import { trans } from '@/app/helpers'
import Image from '@/app/services/ImageService'

class ServiceResource {
  #original = {}
  #repository = new ServiceResourceRepository()
  image = ''
  name = ''
  ospId = ''

  constructor (object = {}) {
    this.#original = cloneDeep(object)
    this.#repository = new ServiceResourceRepository()
    Object.assign(this, { ...object })
    object.serviceResourceOspId && this.setImage(object.serviceResourceOspId)
  }

  get title () {
    return trans(this.name) || ''
  }

  get info () {
    return trans(this.infoText) || ''
  }

  async setImage (serviceResourceOspId) {
    this.image = await Image.getByServiceResource(serviceResourceOspId)
  }

  addToTarget (data, options = {}) {
    let form = { targetOspId: this.ospId }
    if (typeof data === 'string') {
      form.assigneeOspId = data
    } else {
      form = {
        ...form,
        ...data
      }
    }
    return this.#repository.add(form, options)
  }

  revokeFromTarget (data, options = {}) {
    let form = { targetOspId: this.ospId }
    if (typeof data === 'string') {
      form.assigneeOspId = data
    } else {
      form = {
        ...form,
        ...data
      }
    }
    return this.#repository.revoke(form, options)
  }

  hasLetters (search) {
    const searchTarget = search.toLowerCase()
    return (
      this.title.toLowerCase().indexOf(searchTarget) > -1 ||
      this.info.toLowerCase().indexOf(searchTarget) > -1
    )
  }
}

export default ServiceResource
