import store from '@/store'
import { sleep } from '@/app/helpers'

export default class Image {
  static async getByServiceResource (serviceResourceOspId) {
    const image = store.getters['image/serviceResourcesImage'](serviceResourceOspId)
    if (image) {
      return image
    }
    await store.dispatch('image/getServiceResourceImage', serviceResourceOspId)
    await sleep(50)
    // console.log(store.getters['image/serviceResourcesImage'](serviceResourceOspId))
    return store.getters['image/serviceResourcesImage'](serviceResourceOspId)
  }
}
